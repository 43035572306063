@import '../styles/fonts.css';

.container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.gradient-footer {
    /* fallback for old browsers */
    background: #f9f9f7;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #f9f9f7,  #f9f9f7 
);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #f9f9f7,  #f9f9f7 
);
}

/* Added fonts for text and headers */
.h-footer {
    color: #3d4551;
    font-family: 'Cardo', serif;
}

footer {
    font-family: 'Glacial Indifference', sans-serif;
}

.li-footer {
    color: #3d4551;
    font-family: 'Glacial Indifference', sans-serif;
}

.nav-link-footer {
    color: #3d4551;
    font-family: 'Glacial Indifference', sans-serif;
    text-decoration: none;
}

.nav-link-footer:hover {
    color: #1c1d1f;
  }

.btn.footer-btn {
    color: #fcfcfb;
    background-color: #3333a3;
    font-family: 'Glacial Indifference', sans-serif;
}

.btn.footer-btn:hover {
    border-color: #3d4551;
}

p.p-footer {
    color: #3d4551;
}

.footer-icons {
    color: #3d4551;
}

.fadeout {
    animation: fadeout 3s forwards;
}

@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}