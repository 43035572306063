@import '../styles/fonts.css';

.gradient-custom {
    /* fallback for old browsers */
    background: #3333a3;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(51, 51, 163, 1), rgba(89, 66, 210, 1));
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(51, 51, 163, 1), rgba(89, 66, 210, 1));
}

.link-unstyled {
  text-decoration: none;
  color: inherit;
}

a.nav-link {
  color: #fcfcfb;
  font-family: 'Glacial Indifference', sans-serif;
  text-decoration: none;
}

a.nav-link:hover {
  color: #c9c9c9;
}

button.nav-link.dropdown-toggle {
  color: #fcfcfb;
  font-family: 'Glacial Indifference', sans-serif;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

button.nav-link.dropdown-toggle:hover {
  color: #c9c9c9;
}

button.navbar-toggler {
  color: #fcfcfb;
  border-color: #fcfcfb;
}

span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(252, 252, 251, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


@media (max-width: 992px) {
  .nav-item.me-3 {
    margin-right: 0 !important;
  }

  .nav-link.dropdown-toggle {
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    transition: ease-in-out;
  }
  
}



