.carousel-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
  background-color: #fcfcfb;
}

.carousel-item {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.home {
  color: #171716;
  font-size: 3.33rem;
}

p.p-home {
  font-size: 1.33rem;
}

.btn.home-btn {
  color: #fcfcfb;
  background-color: #3333a3;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: 1.33rem; /* Increase font size */
  padding: 0.6rem 3rem; /* Increase padding, first value is top/bottom padding, second is left/right */

}

.btn.home-btn:hover {
  border-color: #171716;
  
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    width: 100%;
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

.bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .btn-bd-primary {
    --bd-violet-bg: #712cf9;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bd-violet-bg);
    --bs-btn-border-color: var(--bd-violet-bg);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #6528e0;
    --bs-btn-hover-border-color: #6528e0;
    --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #5a23c8;
    --bs-btn-active-border-color: #5a23c8;
  }

.bd-mode-toggle {
    z-index: 1500;
  }


  .logo-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Set the height as per your requirement */
    width: auto; /* Set the width as per your requirement */
    max-width: 500px; /* maximum width of the image */
    max-height: 500px; /* maximum height of the image */
  }
  
  .gradient {
    /* Add dimensions and centering as needed */
    width: 33vw;  /* occupy full viewport width */
    height: auto; /* occupy full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.25rem; /* This will make the corners rounded */
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  #logo {
    /* Add dimensions as needed */
    width: 100%;  /* adjust the value as per your requirement */
    height: auto; /* this will keep the image aspect ratio intact */
    max-width: 500px; /* maximum width of the image */
    max-height: 500px; /* maximum height of the image */
    border-radius: 4.25rem; /* This will make the corners rounded */
  }
  
  @media only screen and (max-width: 600px) {
    #logo {
      width: 81vw;
      max-width: 500px; /* maximum width of the image */
      max-height: 500px; /* maximum height of the image */
      border-radius: 2.25rem;  
    }
  }
  
  
