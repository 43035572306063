@font-face {
    font-family: 'Glacial Indifference';
    src: url('../assets/fonts/GlacialIndifference-Regular.otf') format('otf'),
         url('../assets/fonts/GlacialIndifference-Bold.otf') format('otf'),
         url('../assets/fonts/GlacialIndifference-Italic.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
     font-family: 'Cardo';
     src: url('../assets/fonts/Cardo-Regular.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
   }
   
   @font-face {
     font-family: 'Cardo';
     src: url('../assets/fonts/Cardo-Bold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
   }
   
   @font-face {
     font-family: 'Cardo';
     src: url('../assets/fonts/Cardo-Italic.ttf') format('truetype');
     font-weight: 400;
     font-style: italic;
   }
   