@import '../styles/fonts.css';

.call-to-action-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
    background-color: #fcfcfb;
  }

/* Added fonts for text and headers */
.h-call-to-action {
    color: #fcfcfb;
    font-family: 'Cardo', serif;
}

.call-to-action {
    background-color: #212463;
    color: #fcfcfb;
    font-family: 'Glacial Indifference', sans-serif; 
  }

.btn.call-to-action-btn {
    color: #fcfcfb;
    border-color: #fcfcfb;
}

.gradient-call-to-action {
    /* fallback for old browsers */
    background: #3333a3;
  
    /* Chrome 10-25, Safari 5.1-6 
    background-image: -webkit-linear-gradient(to right, rgba(51, 51, 163, 0.8), rgba(33, 36, 99, 0.8),
    url('./call-to-action-bg.png'));*/
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-image: linear-gradient(to right, rgba(51, 51, 163, 1), rgba(89, 66, 210, 1),
    url('./call-to-action-bg.png'));

    /* Background image properties */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}