@import '../styles/fonts.css';


.contact-background-image {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/images/contact_hero_graphic.png') no-repeat center center;
    background-size: cover;
    height: 500px; /* Adjust this value based on your needs */
}

h1 {
    color: #f9f9f7;
    font-family: 'Cardo', serif;
    font-size: 3.96rem;
}

.contact-hero-text {
    color: #f9f9f7;
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 1.3rem;
    margin-left: 6rem;

}

h2,h3,h4,h5 {
    color: #171716;
    font-family: 'Cardo', serif;
}

.image-container {
    position: relative;
    background-color: #3333a3;
  }
  
.card-image {
    width: 39%;
    height: auto;
  }
  
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f9f9f7;
    font-size: 1.99rem;
  }
  
.card {
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075); /* This is equivalent to shadow-sm in Bootstrap */
}

.card:hover {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175); /* This is equivalent to shadow-lg in Bootstrap */
    transform: translateY(-3px); /* Optional: Add a slight upward motion when hovering */

}

.form-group {
    margin-bottom: 1rem;
}

.btn.contact-btn {
    color: #fcfcfb;
    background-color: #3333a3;
    font-family: 'Glacial Indifference', sans-serif;
}

.btn.contact-btn:hover {
    border-color: #3d4551;
}

.link-stc-styled {
    color: #3333a3;
}