@import '../styles/fonts.css';


.decision-sci-background-image {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/images/decision_sciences_hero.png') no-repeat center center;
    background-size: cover;
    height: 500px; /* Adjust this value based on your needs */
}

h1 {
    color: #f9f9f7;
    font-family: 'Cardo', serif;
    font-size: 3.69rem;
}

h2,h3,h4,h5 {
    color: #171716;
    font-family: 'Cardo', serif;
}